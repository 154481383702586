<template>
  <div>
    <van-nav-bar
      :title="item.title"
      left-text="back"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="van-empty__description">
      <div class="content" v-html="item.content"></div>
    </div>
    <siteabbar :idx="3" />
  </div>
</template>

<script>
import Siteabbar from "../components/Sitetabbar";
import { useRouter } from "vue-router";
import { getContact } from "@/api/query";
import { reactive, onMounted, toRefs } from "vue";

export default {
  components: {
    [Siteabbar.name]: Siteabbar,
  },
  setup() {
    const state = reactive({
      item: {},
    });
    const router = useRouter();
    const onClickLeft = () => {
      router.push({
        path: "/index",
      });
    };
    onMounted(async () => {
      const { data } = await getContact();
      state.item = data;
    });
    return {
      onClickLeft,
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped>
::v-deep p {
  color: #333;
  font-size: 16px;
  text-indent: 32px;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
}

::v-deep h3 {
  font-size: 18px;
  line-height: 2;
  text-align: center;
  width: 100%;
}

.content {
  padding: 0;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-all;
  /deep/ img {
    max-width: 100%;
    background: #f9f9f9;
  }
}
</style>
